<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    class="listing--form-modal"
  >
    <div class="modal--wrapper">
      <section class="modal--container">
        <div class="top--image">
          <img src="@/assets/img/icons/checkmark-2.svg" />
        </div>
        <div class="title--text">
          {{ $t('general.success') }}
        </div>
        <div class="desc">
          {{ message }}
        </div>
        <hr class="separator" />
        <div class="d-flex flex-wrap justify-content-center">
          <div class="btn btn-primary--outline mr-4" @click="navigateMylisting">
            {{ $t('general.btn.viewListing') }}
          </div>
          <a v-if="form === 'add'" class="btn btn-primary" :href="'/listing/add'">
            {{ $t('general.btn.addNewListing') }}
          </a>
        </div>
      </section>
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'modal-success-listing-form',
  components: {},
  computed: {
    ...mapState({
      form: state => state.v2.listingForm.form,
      page: state => state.v2.listingForm.page,
      listingType: state => state.v2.listingForm.listingType,
    }),
    hrefMyListing() {
      return `/dashboard/mylisting?listing_type=${this.listingType}`;
    },
    show() {
      return this.$store.getters['v2/listingForm/isCompleteFormModalShow'];
    },
    action() {
      return this.$store.getters['v2/listingForm/completeFormModalAction'];
    },
    message() {
      if (this.action === 'PUBLISH') {
        return this.$t('warning.addListing.modal.publish.message');
      } else if (this.form === 'add') {
        return this.$t('warning.addListing.modal.saveDraft.message');
      } else if (this.form === 'edit') {
        return this.$t('warning.addListing.modal.saveDraft.message');
      } else {
        return null;
      }
    },
  },
  data: () => ({
    modalName: 'modal-success-listing-form',
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
      this.$store.commit('v2/listingForm/CLOSE_COMPLETE_FORM_MODAL');
    },
    navigateMylisting() {
      this.$router.push(this.hrefMyListing);
    },
  },
};
</script>
